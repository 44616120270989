<template>
  <div>
    <qrcode-stream
      @decode="onDecode"
      @init="onInit"
      v-if="certificate.length == 0"
    />
    <Prescription :certificate="certificate" v-else />
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

import Prescription from '@/components/Prescription'

export default {
  name: 'Scanner',

  components: {
    QrcodeStream,
    Prescription
  },

  data() {
    return {
      dialog: false,
      certificate: []
    }
  },

  methods: {
    onDecode(result) {
      this.$http.get(`/certificate/validate/${result}`).then((resp) => {
        this.certificate = resp.data.data
      })
    },

    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = 'ERROR: you need to grant camera access permisson'
        } else if (error.name === 'NotFoundError') {
          this.error = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          this.error = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          this.error = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'ERROR: installed cameras are not suitable'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'ERROR: Stream API is not supported in this browser'
        }
      }
    }
  }
}
</script>
